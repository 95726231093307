export const styles = {
  text: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
  },

  row: {
    gap: "10px",
    display: "flex",
    alignItems: "center",
  },

  wrapper: {
    gap: "6px",
    padding: "0px",
    display: "flex",
    marginTop: "8px",
    marginBottom: "7px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
};
