import { mainTheme } from "shared/ui/styles";

import { createGradient } from "@/shared/lib/helpers";

const gradient = createGradient();

export const styles = {
  imageContainer: {
    width: "200px",
    flex: "0 0 auto",
    position: "relative",
    ...gradient,

    [mainTheme.breakpoints.down("searchMobile")]: {
      height: "220px",
      width: "stretch",
    },

    "&:hover .swiper-button": {
      opacity: "1 !important",
      pointerEvents: "auto !important",
    },

    "& .swiper-pagination-bullet-active": {
      width: "14px",
      borderRadius: "100px",
      backgroundColor: "dominant.white",
    },

    "& .swiper-pagination-bullet": {
      opacity: 1,
      backgroundColor: "rgba(255,255,255, 0.6)",
      transition: "width .3s, background-color .4s",
    },
  },
};
