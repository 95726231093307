import { FC } from "react";

import { SxProps } from "@mui/material";

import { TourHotelTags } from "../TourHotelTags";
import { styles } from "./styles";

import { LikeButton } from "@/features/tours/LikeButton";

import { HotelImageSwiper } from "@/entities/hotels";

import { THotelImages, TCountriesTags } from "@/shared/model/types/common";
import { EBreakpoints } from "@/shared/ui/styles/theme/types";

type TProps = {
  id?: string;
  hotelId: string;
  onClick: () => void;
  inFavourites: boolean;
  images: THotelImages[];
  tags: TCountriesTags[];
};

export const TourCardSwiper: FC<TProps> = ({
  id,
  tags,
  images,
  hotelId,
  onClick,
  inFavourites,
}) => {
  return (
    <HotelImageSwiper
      images={images}
      onClick={onClick}
      sx={styles.imageContainer as SxProps}
      HotelTags={<TourHotelTags tags={tags} />}
      customBreakPoint={EBreakpoints.searchMobile}
      LikeButton={
        <LikeButton id={id} hotelId={hotelId} inFavourites={inFavourites} />
      }
    />
  );
};
