"use client";
import { FC } from "react";

import { Box, Rating, SxProps, Divider, Skeleton } from "@mui/material";
import * as CSS from "csstype";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { LoaderButton } from "@/shared/ui";

type TProps = {
  isDesktop: boolean;
  sx: Record<string, string | number>;
};

export const TourListLoader: FC<TProps> = ({ sx = {}, isDesktop = false }) => {
  const t = useTranslations();

  if (!isDesktop) {
    return (
      <Box sx={{ ...styles.skeleton, ...sx }}>
        {/* Image */}
        <Skeleton
          height={200}
          variant="rectangular"
          sx={
            {
              ...styles.skeleton.skeletonImage,
              ...styles.skeleton.skeletonImage.mobile,
            } as CSS.Properties
          }
        />
        <Box
          sx={
            {
              ...styles.skeleton.skeletonContainer,
              ...styles.skeleton.skeletonContainer.mobile,
            } as CSS.Properties
          }
        >
          <Box sx={{ ...styles.skeleton.skeletonBox, width: "100%" }}>
            <Rating disabled size={"small"} />
            {Array.from({ length: 2 }, (_, index) => (
              <Skeleton
                key={index}
                width={index === 0 ? "70%" : "35%"}
                height={index === 0 ? 36 : undefined}
                sx={styles.skeleton.skeletonBox.skeletonText}
              />
            ))}

            <Divider sx={{ margin: "15px 0 0 0" }} />

            {/* Amenities */}
            <Box
              sx={
                {
                  ...styles.skeleton.skeletonFlex,
                  ...styles.skeleton.skeletonFlex.mobile,
                } as CSS.Properties
              }
            >
              {Array.from({ length: 3 }, (_, index) => (
                <Skeleton
                  key={index}
                  sx={{
                    ...styles.skeleton.skeletonBox.skeletonText,
                    width: "150px",
                  }}
                />
              ))}
            </Box>
          </Box>

          {/* Price */}
          <Box sx={{ ...styles.skeleton.skeletonFlex, marginTop: "5%" }}>
            <Box
              sx={
                {
                  ...styles.skeleton.skeletonBox,
                  ...styles.skeleton.skeletonBox.mobile,
                } as CSS.Properties
              }
            >
              {Array.from({ length: 2 }, (_, index) => (
                <Skeleton
                  key={index}
                  width={index === 1 ? "50%" : "35%"}
                  height={index === 1 ? 36 : undefined}
                  sx={styles.skeleton.skeletonBox.skeletonText}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ ...styles.skeleton, ...sx }}>
      <Skeleton
        height={"100%"}
        variant="rectangular"
        sx={{
          ...styles.skeleton.skeletonImage,
          width: "100%",
          maxWidth: "200px",
        }}
      />
      <Box sx={styles.skeleton.skeletonContainer as SxProps}>
        <Box sx={{ ...styles.skeleton.skeletonBox, width: "100%" }}>
          <Rating disabled size={"small"} sx={{ marginTop: "5px" }} />
          {Array.from({ length: 2 }, (_, index) => (
            <Skeleton
              key={index}
              sx={styles.skeleton.skeletonBox.skeletonText}
            />
          ))}

          <Box
            sx={
              {
                ...styles.skeleton.skeletonFlex,
                ...styles.skeleton.skeltonFlexCol,
              } as CSS.Properties
            }
          >
            {Array.from({ length: 3 }, (_, index) => (
              <Skeleton
                key={index}
                sx={{
                  ...styles.skeleton.skeletonBox.skeletonText,
                  width: "150px",
                }}
              />
            ))}
          </Box>
        </Box>
        <Box sx={styles.skeleton.skeletonFlex as CSS.Properties}>
          <Box sx={styles.skeleton.skeletonBox}>
            {Array.from({ length: 2 }, (_, index) => (
              <Skeleton
                key={index}
                width={index === 1 ? "100%" : "75%"}
                height={index === 1 ? 36 : undefined}
                sx={styles.skeleton.skeletonBox.skeletonText}
              />
            ))}
          </Box>

          <LoaderButton
            loading
            sx={styles.skeleton.loaderButton as CSS.Properties}
          >
            {t("SEE_TOUR")}
          </LoaderButton>
        </Box>
      </Box>
    </Box>
  );
};
