/* eslint-disable perfectionist/sort-objects */
import { sample } from "effector";
import { createGate } from "effector-react";

import { getHotToursQuery } from "@/shared/model/api/tours";

export const HotToursGate = createGate();

sample({
  clock: HotToursGate.open,
  fn: () => undefined,
  target: getHotToursQuery.refresh,
});
