"use client";
import { useSearchParams } from "next/navigation";
import { FC, memo, useMemo, useState, useCallback } from "react";

import { Place } from "@mui/icons-material";
import { Box, Stack, Button, SxProps, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { DefferedPaymentTooltip } from "../DefferedPaymentTooltip";
import { TourCardSwiper } from "../TourCardSwiper";
import { TourInfoMobile } from "../TourInfoMobile";
import { styles } from "./styles";

import { HotelAmenities } from "@/entities/hotels";

import { Condition } from "@/shared/lib/condition";
import { DEFAULT_AMOUNT_OF_ADULTS } from "@/shared/lib/constants";
import { priceFormatter, getHotelAddress } from "@/shared/lib/helpers";
import { useGetDeclinations, useSearchBreakpoints } from "@/shared/lib/hooks";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { ESearchType } from "@/shared/model/types/search";
import { TTourItem } from "@/shared/model/types/tours";
import {
  HotelRating,
  DeferredPayment,
  HotelStarsRating,
  TooltipWithTextSlice,
} from "@/shared/ui";

type TProps = TTourItem & {
  sx: SxProps;
  index: number;
  onClick?: () => void;
  selectionView?: boolean;
  MapButtonComponent?: JSX.Element;
  queryParams?: Record<string, string | string[]>;
};

const DesktopButton: FC<{
  id?: string;
  expired: boolean;
  onClick: () => void;
  formattedTourInfo: string;
}> = ({ id, onClick, expired, formattedTourInfo }) => {
  const t = useTranslations();

  if (expired) {
    return (
      <Button
        disabled
        disableElevation
        variant="contained"
        sx={styles.buyButton}
      >
        {t("UNAVAILABLE")}
      </Button>
    );
  }

  return (
    <Box>
      <Typography component="div" variant="caption" sx={styles.dateContainer}>
        <TooltipWithTextSlice sliceAt={50} title={formattedTourInfo} />
      </Typography>
      <Button
        id={id}
        disableElevation
        onClick={onClick}
        variant="contained"
        sx={{
          ...styles.buyButton,
          ...styles.buyButton.toursButton,
        }}
      >
        {t("SEE_TOUR")}
      </Button>
    </Box>
  );
};

export const TourCard: FC<TProps> = memo(
  ({
    hotel,
    index,
    nights,
    sx = {},
    onClick,
    check_in,
    price = 0,
    check_out,
    tags = [],
    infants = 0,
    queryParams,
    nights_total,
    payment_plan,
    children = 0,
    expired = false,
    MapButtonComponent,
    selectionView = false,
    full_price: fullPrice = 0,
    adults = DEFAULT_AMOUNT_OF_ADULTS,
  }: TProps) => {
    const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

    const { isTablet, isDesktop } = useSearchBreakpoints({
      type: ESearchType.Main,
    });

    const { adultsText, childrenText, nightsTotalText } = useGetDeclinations({
      adults,
      nights,
      nightsTotal: nights_total,
      children: children + infants,
    });

    const t = useTranslations();
    const dIn = dayjs(check_in).format("D MMM");
    const dOut = dayjs(check_out).format("D MMM");

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const searchParams = useSearchParams();

    const lengthOfHotelInfo = useMemo(
      () =>
        isDesktop ? { name: 35, address: 28 } : { name: 24, address: 100 },
      [isDesktop],
    );

    const handleCardClick = useCallback(() => {
      if (selectionView && onClick) {
        onClick();
        return;
      }

      try {
        sendDataLayerEventFn({
          event: "purchaseTour",
          data: {
            hotel: hotel.name,
            hotelPosition: index + 1,
            country: hotel.country.name,
            hotelStars: hotel.category?.value,
            regions: hotel.locations.map((item) => item.name),
          },
        });
      } catch (error) {
        console.log(error);
      }

      const newQueryParams =
        searchParams?.toString() ||
        Object.entries(queryParams ?? {})
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              return value.map((val) => `${key}=${val}`).join("&");
            } else {
              return `${key}=${value}`;
            }
          })
          .join("&");

      window.open(`/oteli/${hotel.slug}?${newQueryParams}`, "_blank");
    }, [
      selectionView,
      onClick,
      searchParams,
      queryParams,
      hotel.slug,
      hotel.name,
      hotel.country.name,
      hotel.category?.value,
      hotel.locations,
      sendDataLayerEventFn,
      index,
    ]);

    const headingBoxStyles = !hotel.category?.value
      ? styles.heading.headingBox.withoutCategory
      : {};

    const ratingStylesWithoutCategory = !hotel.category?.value
      ? styles.heading.headingBox.desktopRating
      : {};

    const addressText = useMemo(() => getHotelAddress(hotel), [hotel]);

    if (isDesktop || isTablet) {
      return (
        <Box sx={{ ...styles.card, ...sx } as SxProps}>
          <TourCardSwiper
            tags={tags}
            hotelId={hotel.id}
            images={hotel.images}
            onClick={handleCardClick}
            inFavourites={hotel.favorite}
            id={`search_favourites_btn_${index}_test`}
          />

          <Box sx={styles.contentWrapper}>
            <Stack>
              <Box sx={styles.heading}>
                <Box sx={{ ...styles.heading.headingBox, ...headingBoxStyles }}>
                  <Condition match={!!hotel.category?.value}>
                    <HotelStarsRating
                      rating={
                        // eslint-disable-next-line
                        hotel.category?.value!
                      }
                    />
                  </Condition>
                  <Typography sx={ratingStylesWithoutCategory}>
                    <HotelRating
                      rating={hotel.rating || 0}
                      reviewsCount={hotel.reviews_count}
                    />
                  </Typography>
                </Box>

                <Box sx={styles.flexCol}>
                  <Typography
                    variant="body1"
                    onClick={handleCardClick}
                    sx={styles.heading.title}
                  >
                    <TooltipWithTextSlice
                      title={hotel.name}
                      textSx={styles.tooltipSlice}
                      sliceAt={lengthOfHotelInfo.name}
                    />
                  </Typography>

                  <Box sx={styles.heading.address}>
                    <Place
                      sx={{ fontSize: "16px", color: "content.grey-200" }}
                    />

                    <TooltipWithTextSlice
                      placement="bottom"
                      title={addressText}
                      sliceAt={lengthOfHotelInfo.address}
                      textSx={styles.heading.address.tooltip as SxProps}
                    />

                    {MapButtonComponent}
                  </Box>
                </Box>
              </Box>

              <Condition match={!!hotel.amenities.length}>
                <Box sx={styles.amenities}>
                  <HotelAmenities
                    id={hotel.id}
                    amenities={hotel.amenities.slice(0, 3)}
                  />
                </Box>
              </Condition>
            </Stack>

            <Box sx={styles.flexCol}>
              <Box sx={styles.flexContainer}>
                <Box sx={styles.footerDesktop}>
                  <Condition match={!!fullPrice && fullPrice !== price}>
                    <Typography sx={styles.priceWithoutDiscount}>
                      {priceFormatter(fullPrice)}
                    </Typography>
                  </Condition>

                  <Typography sx={styles.price}>
                    {selectionView
                      ? priceFormatter(price)
                      : t("PRICE_FROM", { amount: priceFormatter(price) })}
                  </Typography>

                  <Condition match={!!payment_plan}>
                    <DeferredPayment
                      open={tooltipOpen}
                      currency={payment_plan?.currency}
                      title={<DefferedPaymentTooltip />}
                      onMouseEnter={() => setTooltipOpen(true)}
                      onMouseLeave={() => setTooltipOpen(false)}
                      paymentPerMonth={payment_plan?.amount ?? 0}
                      onClick={() => setTooltipOpen(!tooltipOpen)}
                    />
                  </Condition>
                </Box>

                <DesktopButton
                  expired={expired}
                  onClick={handleCardClick}
                  id={`open_tour_btn_${index}_test`}
                  formattedTourInfo={`${dIn} – ${dOut}, ${nightsTotalText}${isDesktop ? ", " : "\n"}${adultsText}${childrenText}`}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ ...styles.card, ...sx } as SxProps}>
        <TourCardSwiper
          tags={tags}
          hotelId={hotel.id}
          images={hotel.images}
          onClick={handleCardClick}
          inFavourites={hotel.favorite}
          id={`search_favourites_btn_${index}_test`}
        />

        <Box
          onClick={handleCardClick}
          sx={styles.contentWrapper}
          id={`open_tour_btn_${index}_test`}
        >
          <Stack>
            <Box sx={styles.heading}>
              <Box sx={{ ...styles.heading.headingBox, ...headingBoxStyles }}>
                <Condition match={!!hotel.category?.value}>
                  <HotelStarsRating
                    rating={
                      // eslint-disable-next-line
                      hotel.category?.value!
                    }
                  />
                </Condition>

                <Typography sx={styles.heading.headingBox.rating}>
                  <HotelRating
                    rating={hotel.rating || 0}
                    reviewsCount={hotel.reviews_count}
                  />
                </Typography>
              </Box>

              <Box sx={styles.flexCol}>
                <Typography
                  variant="body1"
                  onClick={handleCardClick}
                  sx={styles.heading.title}
                >
                  <TooltipWithTextSlice
                    title={hotel.name}
                    textSx={styles.tooltipSlice}
                    sliceAt={lengthOfHotelInfo.name}
                  />
                </Typography>
              </Box>

              <Condition match={!!hotel.amenities.length}>
                <Box sx={styles.amenitiesWrapper}>
                  <Box sx={styles.amenities}>
                    <HotelAmenities id={hotel.id} amenities={hotel.amenities} />
                  </Box>
                </Box>
              </Condition>
            </Box>

            <Box sx={styles.hr} />
            <TourInfoMobile
              adults={adults}
              nights={nights}
              infants={infants}
              checkIn={check_in}
              checkOut={check_out}
              isDesktop={isDesktop}
              childrenValue={children}
              nightsTotal={nights_total}
            />
          </Stack>

          <Box sx={styles.flexCol}>
            <Box sx={styles.flexContainer}>
              <Box sx={styles.priceWrapper}>
                <Condition match={!!fullPrice && fullPrice !== price}>
                  <Typography sx={styles.priceWithoutDiscount}>
                    {priceFormatter(fullPrice)}
                  </Typography>
                </Condition>
                <Box sx={styles.priceContainer}>
                  <Typography sx={styles.price}>
                    {selectionView
                      ? priceFormatter(price)
                      : t("PRICE_FROM", { amount: priceFormatter(price) })}
                  </Typography>

                  <Condition match={!!payment_plan}>
                    <DeferredPayment
                      open={tooltipOpen}
                      currency={payment_plan?.currency}
                      title={<DefferedPaymentTooltip />}
                      paymentPerMonth={payment_plan?.amount ?? 0}
                      onClick={() => setTooltipOpen(!tooltipOpen)}
                    />
                  </Condition>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
);
