import { FC, useMemo } from "react";

import { Box, SxProps } from "@mui/material";

import { getTagIcon } from "../../lib/helpers";
import { styles } from "./styles";

import { TCountriesTags } from "@/shared/model/types/common";
import { Badge } from "@/shared/ui";

const isSafari =
  typeof window !== "undefined" ? /safari/i.test(navigator.userAgent) : false;

const isIphone =
  typeof window !== "undefined"
    ? /iPhone|iPad/i.test(navigator.userAgent)
    : false;

const deceptiveStyleForIphone =
  isSafari && isIphone ? { top: 0, display: "flex", position: "relative" } : {};

type TProps = {
  tags: TCountriesTags[];
};

export const TourHotelTags: FC<TProps> = ({ tags = [] }) => {
  const slicedTags = useMemo(() => tags.slice(0, 3), [tags]);

  if (!tags.length) {
    return null;
  }

  return (
    <Box sx={{ ...styles.wrapper, ...deceptiveStyleForIphone } as SxProps}>
      {slicedTags.map((tag) => (
        <Badge
          key={tag.id}
          size="large"
          sx={{
            ...styles.burningTour,
            color: tag.meta.font_color,
            backgroundColor: tag.meta.bg_color,
          }}
          icon={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            tag.meta.icon ? getTagIcon(tag.meta.icon) : undefined
          }
        >
          {tag.name}
        </Badge>
      ))}
    </Box>
  );
};
