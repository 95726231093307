import { HOTEL_TAG_ICONS } from "./icons";

export const getTagIcon = (tag: string) => {
  switch (tag) {
    case "eco":
      return HOTEL_TAG_ICONS.eco;
    case "fire":
      return HOTEL_TAG_ICONS.fire;
    default:
      "";
  }
};
