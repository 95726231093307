import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  skeleton: {
    gap: "16px",
    display: "flex",
    borderRadius: "8px",
    border: "1px solid",
    paddingRight: "16px",
    marginBottom: "24px",
    borderColor: "grey.white",
    backgroundColor: "dominant.white",

    loaderButton: {
      fontSize: "14px",
      lineHeight: "16px",
      whiteSpace: "nowrap",
    },

    skeltonFlexCol: {
      marginTop: "20px",
      flexDirection: "column",
      alignItems: "flex-start",
    },

    skeletonImage: {
      borderRadius: "6px 0 0 7px",
      backgroundColor: "grey.white",

      mobile: {
        width: "100%",
        maxWidth: "100%",
      },
    },

    [mainTheme.breakpoints.down("smd")]: {
      paddingRight: "0",
      marginTop: "16px",
      borderRadius: "12px",
      flexDirection: "column",
      filter: "drop-shadow(0px 2px 3px rgba(14, 38, 65, 0.14))",
    },

    skeletonFlex: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      mobile: {
        marginTop: "20px",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },

    skeletonBox: {
      width: "150px",

      mobile: {
        width: "100%",
      },

      [mainTheme.breakpoints.down("smd")]: {
        padding: "0 16px",
      },

      skeletonText: {
        borderRadius: "4px",
        backgroundColor: "grey.white",

        mobile: {
          height: "24px",
        },
      },
    },

    skeletonContainer: {
      width: "100%",
      display: "flex",
      padding: "8px 0",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",

      mobile: {
        flexDirection: "column",
      },

      [mainTheme.breakpoints.down("smd")]: {
        padding: "0",
      },
    },
  },
};
