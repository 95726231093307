import { getHeadingCaptionsQuery } from "@/shared/model/api/common";

const $normalizedHeadingData = getHeadingCaptionsQuery.$data.map((data) => ({
  title: data?.title,
  notice: data?.notice,
  subtitle: data?.subtitle,
  type: data?.type ?? "default",
  installment: data?.installment,
}));

export const $$headingCaptionModel = {
  $normalizedHeadingData,
};
