import { HOT_TOUR_SLICE } from "./constants";

import { THotTourItem } from "@/shared/model/types/tours";

export const divideToursIntoCategories = (tours?: THotTourItem[]) => {
  if (!tours) {
    return {
      footer: [],
      header: [],
      content: [],
    };
  }

  if (tours.length <= 6) {
    return {
      footer: [],
      content: [],
      header: tours,
    };
  }

  const footerLength = (tours.length - HOT_TOUR_SLICE) % 4;
  const contentLength = tours.length - footerLength;

  return {
    footer: tours.slice(contentLength),
    header: tours.slice(0, HOT_TOUR_SLICE),
    content: tours.slice(HOT_TOUR_SLICE, contentLength),
  };
};
