import { mainTheme } from "shared/ui/styles";

export const styles = {
  wrapper: {
    zIndex: "1",
    display: "flex",
    padding: "12px",
    flexWrap: "wrap",
    position: "absolute",
    flexDirection: "column",
  },

  burningTour: {
    margin: "0 0 8px 0",
    width: "fit-content",
    color: "error.secondary",
    backgroundColor: "accent.red.light",

    [mainTheme.breakpoints.down("smd")]: {
      height: "22px",
      fontSize: "12px",
      margin: "0 6px 6px 0",
    },
  },
};
