import { FC, RefObject, createRef, useCallback } from "react";

import {
  Box,
  Badge,
  SxProps,
  ImageList,
  Typography,
  ImageListItem,
} from "@mui/material";
import dayjs from "dayjs";
import { useUnit } from "effector-react";
import { useLocale, useTranslations } from "next-intl";

import { HOT_TOUR_SLICE } from "./lib/constants";
import { bannerStyles as styles } from "./styles";

import { $$headingCaptionModel } from "@/entities/home-page-texts/model";

import { AVAILABLE_LOCALES } from "@/shared/config/locales/config";
import { Condition } from "@/shared/lib/condition";
import {
  DEFAULT_AMOUNT_OF_ADULTS,
  DEFAULT_AMOUNT_OF_NIGHTS,
} from "@/shared/lib/constants";
import {
  getColumnSize,
  priceFormatter,
  transformDataToQuery,
} from "@/shared/lib/helpers";
import { getSearchParamsString } from "@/shared/lib/helpers/router/getSearchParamsString";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { BACKEND_KEYS } from "@/shared/model/types/common";
import { THotTourItem } from "@/shared/model/types/tours";
import { Link, Image } from "@/shared/ui";

type THotToursBannerProps = {
  isDesktop?: boolean;
  wrapperSx?: SxProps;
  tours: THotTourItem[];
  insideOfCollapse?: boolean;
};

// provided by business
const PAYMENT_SUM_DENOMINATOR = 0.04205;

export const HotToursBanner: FC<THotToursBannerProps> = ({
  tours = [],
  wrapperSx = {},
  isDesktop = false,
  insideOfCollapse = false,
}) => {
  const t = useTranslations();
  const locale = useLocale();
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const { installment } = useUnit($$headingCaptionModel.$normalizedHeadingData);

  const imgListRef: RefObject<HTMLLIElement> = createRef();

  const calcColsSize = useCallback(
    (toursLength: number, index: number, isDesktop: boolean) => {
      const length = insideOfCollapse
        ? toursLength + HOT_TOUR_SLICE
        : toursLength;
      const cardIndex = insideOfCollapse ? index + HOT_TOUR_SLICE : index;

      return getColumnSize(length, cardIndex, isDesktop);
    },
    [insideOfCollapse],
  );

  const handleClick = ({
    country,
    regions,
  }: {
    country: string;
    regions: string;
  }) => {
    try {
      sendDataLayerEventFn({
        data: { country, regions },
        event: "clickOnTheHotToursBanner",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={wrapperSx}>
      <ImageList
        cols={4}
        component={"li"}
        ref={imgListRef}
        sx={styles.grid}
        variant="quilted"
        gap={!isDesktop ? 15 : 24}
        rowHeight={!isDesktop ? 164 : 310}
      >
        {tours.map(
          (
            {
              date = "",
              cover = "",
              children = 0,
              price_pax = 0,
              arrival_country = {},
              arrival_location = {},
              departure_location = {},
              adults = DEFAULT_AMOUNT_OF_ADULTS,
              nights = DEFAULT_AMOUNT_OF_NIGHTS,
            },
            idx,
          ) => {
            const queryData = transformDataToQuery({
              [BACKEND_KEYS.adults]: adults,
              [BACKEND_KEYS.nights]: nights,
              [BACKEND_KEYS.rangeEndDay]: date,
              [BACKEND_KEYS.children]: children,
              [BACKEND_KEYS.rangeStartDay]: date,
              [BACKEND_KEYS.rangeEndNight]: nights,
              [BACKEND_KEYS.rangeStartNight]: nights,
              [BACKEND_KEYS.arrival]: arrival_country.code ?? "",
              [BACKEND_KEYS.childrenAges]: Array(children).fill(0),
              [BACKEND_KEYS.departure]: departure_location.code ?? "",
              [BACKEND_KEYS.selectedRegionIds]:
                typeof arrival_location.id === "string"
                  ? [arrival_location.id]
                  : Array.isArray(arrival_location.id)
                    ? arrival_location.id
                    : null,
            });
            const query = getSearchParamsString(queryData);

            return (
              <Link
                passHref
                key={idx}
                legacyBehavior
                href={`${locale === AVAILABLE_LOCALES[0] ? "" : locale}/search?${query}`}
              >
                <ImageListItem
                  rows={1}
                  component="a"
                  sx={styles.listItem}
                  cols={calcColsSize(tours.length, idx, isDesktop)}
                >
                  <Image
                    src={cover}
                    alt={arrival_location.name ?? arrival_country.name}
                    onClick={() =>
                      handleClick({
                        country: arrival_country.name ?? "",
                        regions: arrival_location.name ?? "",
                      })
                    }
                  />
                  {installment && (
                    <Badge sx={styles.creditBadge}>0-0-{installment}</Badge>
                  )}

                  <Box sx={styles.badge}>
                    <Typography sx={styles.countryName}>
                      {arrival_location.name
                        ? `${arrival_country.name ?? ""}, ${
                            arrival_location.name
                          }`
                        : arrival_country.name}
                    </Typography>
                    <Box sx={styles.priceWrapper}>
                      <Typography sx={styles.price}>
                        {t("PRICE_FROM", { amount: priceFormatter(price_pax) })}
                      </Typography>
                      <Badge>
                        <Typography sx={styles.creditText}>
                          {t("PRICE_FROM_MONTHS_SHORT", {
                            amount: priceFormatter(
                              Math.round(price_pax * PAYMENT_SUM_DENOMINATOR),
                            ),
                          })}
                        </Typography>
                      </Badge>
                    </Box>
                    <Box sx={styles.labels}>
                      <Typography sx={styles.date}>
                        <Condition match={!!date}>
                          {dayjs(date).format("DD MMMM")}
                        </Condition>
                        <Condition match={!!adults}>
                          ,{" "}
                          {!isDesktop
                            ? t("PERSON_SHORTENED", { amount: 1 })
                            : t("PERSON_FULL", { amount: 1 })}
                        </Condition>
                      </Typography>
                      <Condition match={!!children}>
                        <Typography
                          sx={styles.labels}
                          fontSize={!isDesktop ? 14 : 18}
                        >
                          ,{" "}
                          {t("CHILDREN_DECLINATIONS_SHORT", {
                            count: children,
                          })}
                        </Typography>
                      </Condition>
                    </Box>
                  </Box>
                </ImageListItem>
              </Link>
            );
          },
        )}
      </ImageList>
    </Box>
  );
};
