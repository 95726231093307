"use client";
import { FC, useMemo, useState, createRef } from "react";

import { Box, Collapse, Typography, useMediaQuery } from "@mui/material";
import { useGate, useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { HotToursBanner } from "./HotToursBanner";
import { HOT_TOUR_SLICE } from "./lib/constants";
import { divideToursIntoCategories } from "./lib/helpers";
import { HotToursGate } from "./model";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { getHotToursQuery } from "@/shared/model/api/tours";
import { $defaultDeparture } from "@/shared/model/store/default-departure";
import { Container, CollapseButton } from "@/shared/ui";

export const HotTours: FC = () => {
  const t = useTranslations();
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const hotToursRef = createRef<HTMLElement>();
  const defaultDeparture = useUnit($defaultDeparture);

  useGate(HotToursGate);
  const { pending: isLoadng, data: hotToursList } = useUnit(getHotToursQuery);

  const hotToursFromDeparture = hotToursList?.filter(
    ({ departure_location }) =>
      departure_location.code === defaultDeparture?.code,
  );

  const [isCollapseOpened, setCollapseOpened] = useState(false);

  const slicedHotToursData = useMemo(
    () => divideToursIntoCategories(hotToursFromDeparture),
    [hotToursFromDeparture],
  );

  const handleCollapse = () => {
    sendDataLayerEventFn({
      event: "allFeedback",
    });

    if (isCollapseOpened) {
      setCollapseOpened(false);
      hotToursRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    setCollapseOpened(true);
  };

  if (!hotToursFromDeparture?.length || isLoadng) {
    return <></>;
  }

  return (
    <Container
      ref={hotToursRef}
      sx={styles.container}
      disablePadding={!isDesktop}
    >
      <Box sx={styles.heading}>
        <Typography component="h1" sx={styles.title}>
          {t("HOT_TOURS")}
        </Typography>
        <Typography component="h3" sx={styles.subtitle}>
          {t("HOTTOURS_SUBTITLE_MAIN")}
        </Typography>
      </Box>
      <Condition match={isDesktop}>
        <HotToursBanner isDesktop tours={slicedHotToursData.header} />

        <Collapse timeout={500} in={isCollapseOpened}>
          <HotToursBanner
            insideOfCollapse
            isDesktop={isDesktop}
            wrapperSx={styles.banners}
            tours={slicedHotToursData.content}
          />
          <Condition match={Boolean(slicedHotToursData.footer.length)}>
            <HotToursBanner
              isDesktop={isDesktop}
              wrapperSx={styles.banners}
              tours={slicedHotToursData.footer}
            />
          </Condition>
        </Collapse>
      </Condition>

      <Condition match={!isDesktop}>
        <HotToursBanner
          tours={hotToursFromDeparture.slice(0, HOT_TOUR_SLICE)}
        />
        <Collapse timeout={500} in={isCollapseOpened}>
          <HotToursBanner
            insideOfCollapse
            wrapperSx={styles.banners}
            tours={hotToursFromDeparture.slice(HOT_TOUR_SLICE)}
          />
        </Collapse>
      </Condition>
      <Condition match={hotToursFromDeparture.length > HOT_TOUR_SLICE}>
        <Box sx={styles.collapse}>
          <CollapseButton
            variant="outlined"
            onClick={handleCollapse}
            isOpen={isCollapseOpened}
          />
        </Box>
      </Condition>
    </Container>
  );
};
