import { PaletteMode } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { BreakpointOverrides } from "@mui/system/createTheme/createBreakpoints";
import { OverridableStringUnion } from "@mui/types";
import * as CSS from "csstype";

export type TPaletteColors = {
  cloud: string;
  mode: PaletteMode;
  system: {
    blue: string;
  };
  background: {
    default: string;
  };
  info: {
    main: string;
    light: string;
  };

  success: {
    high: string;
    main: string;
    light: string;
  };
  error: {
    main: string;
    light: string;
    secondary: string;
  };
  warning: {
    main: string;
    light: string;
    secondary: string;
  };
  dominant: {
    dop: string;
    main: string;
    cloud: string;
    white: string;
    background: string;
  };
  primary: {
    dark: string;
    main: string;
    light: string;
    whiteblue: string;
    extralight: string;
  };
  secondary: {
    text: string;
    dark: string;
    main: string;
    darkGlass: string;
    contrastText: string;
  };
  content: {
    loan: string;
    primary: string;
    secondary: string;
    "grey-100": string;
    "grey-200": string;
    "grey-400": string;
  };
  grey: {
    text: string;
    dark: string;
    main: string;
    white: string;
    light: string;
    default: string;
    content: string;
    extralight: string;
    bgWhiteBlue: string;
  };
  accent: {
    yellow: {
      low: string;
      loan: string;
      high: string;
    };
    orange: {
      stars: string;
      primary: string;
      attention: string;
    };
    red: {
      light: string;
      error: string;
      "error-dark": string;
      "lightest-hover": string;
    };
    green: {
      tagbg: string;
      light: string;
      rating: string;
      success: string;
      secondary: string;
    };
    brand: {
      dark: string;
      light: string;
      primary: string;
      lightest: string;
      secondary: string;
    };
  };
};

type TCustomBreakPoint = OverridableStringUnion<
  | "xl"
  | "ml"
  | "lg"
  | "md"
  | "sm"
  | "xs"
  | "smd"
  | "searchMobile"
  | "searchDesktop",
  BreakpointOverrides
>;

export enum EBreakpoints {
  xs = 0,
  md = 960,
  sm = 600,
  xl = 1920,
  ml = 1325,
  lg = 1120,
  smd = 750,

  searchMobile = 850,
  searchDesktop = 1180,
}

export type TBreakPoints = {
  values: EBreakpoints;
  unit: string | undefined;
  keys: TCustomBreakPoint[];
  not: (key: TCustomBreakPoint) => string;
  only: (key: TCustomBreakPoint) => string;
  up: (key: number | TCustomBreakPoint) => string;
  down: (key: number | TCustomBreakPoint) => string;
  between: (
    start: number | TCustomBreakPoint,
    end: number | TCustomBreakPoint,
  ) => string;
};

export type TBreakPointOptions = {
  step?: number | undefined;
  unit?: string | undefined;
} & Partial<TBreakPoints>;

type TCustomBreakpoints = TBreakPointOptions & {
  values: EBreakpoints;
  keys: TCustomBreakPoint[];
};

type TCustomScrollbars = {
  default: {
    "&::-webkit-scrollbar": CSS.Properties;
    "&::-webkit-scrollbar-thumb": CSS.Properties;
    "&::-webkit-scrollbar-thumb:hover": CSS.Properties;
  };
};

export type TCustomTheme = Theme & {
  scrollbars: TCustomScrollbars;
  palette: TPaletteColors & Theme["palette"];
  breakpoints: undefined | (TCustomBreakpoints & Theme["breakpoints"]);
};
