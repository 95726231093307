import { FC } from "react";

import { Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

export const DefferedPaymentTooltip: FC = () => {
  const t = useTranslations();

  return (
    <>
      <Typography sx={styles.tooltipTitle}>
        {t("IN_INSTALLMENTS_OR_CREDIT")}
      </Typography>
      <Typography sx={styles.tooltipText}>
        {t("FINAL_PRICE_MAY_VARY_DEPENDING_ON_SELECTION_PARAMETERS")}
      </Typography>
    </>
  );
};
