import { FC } from "react";

import { Person, Bedtime } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

import { styles } from "./styles";

import {
  DEFAULT_AMOUNT_OF_NIGHTS,
  DEFAULT_AMOUNT_OF_ADULTS,
  DEFAULT_AMOUNT_OF_INFANTS,
  DEFAULT_AMOUNT_OF_CHILDREN,
} from "@/shared/lib/constants";
import { useGetDeclinations } from "@/shared/lib/hooks";

type TProps = {
  nights: number;
  adults: number;
  infants: number;
  checkIn: string;
  checkOut: string;
  nightsTotal: number;
  isDesktop?: boolean;
  childrenValue: number;
};

export const TourInfoMobile: FC<TProps> = ({
  checkIn,
  checkOut,
  nightsTotal,
  isDesktop = false,
  adults = DEFAULT_AMOUNT_OF_ADULTS,
  nights = DEFAULT_AMOUNT_OF_NIGHTS,
  infants = DEFAULT_AMOUNT_OF_INFANTS,
  childrenValue = DEFAULT_AMOUNT_OF_CHILDREN,
}) => {
  const { adultsText, childrenText, nightsTotalText } = useGetDeclinations(
    {
      adults,
      nights,
      nightsTotal,
      children: childrenValue + infants,
    },
    isDesktop,
  );

  const dIn = dayjs(checkIn).format("D MMM");
  const dOut = dayjs(checkOut).format("D MMM");

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.row}>
        <Person fontSize="small" sx={{ fontSize: "16px" }} />
        <Typography sx={styles.text}>
          {adultsText}, {childrenText}
        </Typography>
      </Box>
      <Box sx={styles.row}>
        <Bedtime
          fontSize="small"
          sx={{ fontSize: "12px", marginLeft: "4px" }}
        />
        <Typography sx={styles.text}>
          {nightsTotalText} · {dIn} – {dOut}
        </Typography>
      </Box>
    </Box>
  );
};
