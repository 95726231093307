import { COLORS, mainTheme } from "shared/ui/styles";

export const styles = {
  tooltipSlice: {
    margin: 0,
    display: "inline",
  },

  footerDesktop: {
    width: "100%",
    maxWidth: "60%",
  },

  priceWrapper: {
    width: "100%",
    marginTop: "19px",
  },

  flexCol: {
    display: "flex",
    flexDirection: "column",
  },

  amenitiesWrapper: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  hr: {
    width: "100%",
    height: "1px",
    margin: "8px 0 8px 0",
    background: COLORS.CLOUD,
  },

  amenityBadge: {
    gap: "8px",
    display: "flex",
    color: COLORS.GRAY,
    whiteSpace: "nowrap",
    fontWeight: "500 !important",
  },

  priceContainer: {
    gap: "12px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  priceWithoutDiscount: {
    fontSize: 14,
    fontWeight: 400,
    color: "#ED5D5D",
    lineHeight: "16px",
    textDecoration: "line-through",
  },

  flexContainer: {
    width: "100%",
    display: "flex",
    marginTop: "auto",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },

  buyButton: {
    height: "unset",
    fontWeight: 500,
    fontSize: "12px",
    marginTop: "14px",
    lineHeight: "14px",
    padding: "8px 16px",

    toursButton: {
      marginTop: "4px",
      width: "stretch",
    },
  },

  dateContainer: {
    fontSize: 12,
    fontWeight: 500,
    textAlign: "end",
    maxWidth: "100%",
    lineHeight: "16x",
    color: "grey.light",

    [mainTheme.breakpoints.down("searchMobile")]: {
      maxWidth: "65%",
      fontWeight: 400,
      color: "grey.dark",
      lineHeight: "14px",
    },
  },

  price: {
    fontSize: 24,
    display: "flex",
    fontWeight: 500,
    lineHeight: "28px",
    alignItems: "flex-end",

    [mainTheme.breakpoints.down("lg")]: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",
    },

    [mainTheme.breakpoints.down("searchMobile")]: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",
    },
  },

  contentWrapper: {
    width: "100%",
    color: "unset",
    display: "flex",
    padding: "16px",
    columnGap: "16px",
    position: "relative",
    textDecoration: "none",
    flexDirection: "column",
    justifyContent: "space-between",
    gridTemplateColumns: "minmax(0, 410px) minmax(0, 50%)",

    [mainTheme.breakpoints.down("searchMobile")]: {
      padding: "12px",
      display: "flex",
      cursor: "pointer",
      height: "calc(100% - 220px)",
      justifyContent: "space-between",
    },
  },

  amenities: {
    rowGap: "4px",
    display: "flex",
    marginBottom: "10px",
    alignSelf: "flex-start",
    flexDirection: "column",

    [mainTheme.breakpoints.down("searchMobile")]: {
      gap: "12px",
      padding: "0px",
      display: "flex",
      width: "stretch",
      overflowX: "auto",
      overflowY: "hidden",
      marginBottom: "8px",
      flexDirection: "row",
      scrollbarWidth: "none",
      gridTemplateColumns: "0",
      justifyContent: "flex-start",

      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },

  card: {
    width: "100%",
    display: "flex",
    minHeight: "220px",
    overflow: "hidden",
    border: "1px solid",
    borderRadius: "8px",
    position: "relative",
    marginBottom: "24px",
    borderColor: "grey.white",
    justifyContent: "space-between",
    transition: "box-shadow .3s ease",
    backgroundColor: "dominant.white",

    "&:last-child": {
      marginBottom: 0,
    },

    "&:hover": {
      border: "1px solid #2b6bb2",
      boxShadow: "0px 0px 15px rgba(74, 85, 104, 0.1)",
    },

    [mainTheme.breakpoints.down("searchMobile")]: {
      display: "inherit",
      borderRadius: "12px",
      filter: "drop-shadow(0px 2px 3px rgba(14, 38, 65, 0.14))",
    },
  },

  heading: {
    marginBottom: "12px",

    [mainTheme.breakpoints.down("searchMobile")]: {
      height: "74px",
      marginBottom: "0",
    },

    titleWrapper: {
      gap: "8px",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },

    title: {
      gap: "8px",
      color: "black",
      maxWidth: "45ch",
      fontSize: "18px",
      display: "inline",
      cursor: "pointer",
      textDecoration: "none",
      transition: "color .3s ease",

      "&:hover": {
        color: "primary.main",
      },

      [mainTheme.breakpoints.down("searchMobile")]: {
        width: "253px",
        fontWeight: 500,
        maxWidth: "100%",
        fontSize: "18px",
        lineHeight: "22px",
        paddingBottom: "8px",
      },
    },

    address: {
      columnGap: "3px",
      marginTop: "4px",
      lineHeight: "12px",
      alignItems: "center",
      color: "grey.content",
      display: "inline-flex",

      [mainTheme.breakpoints.down("searchMobile")]: {
        width: "100%",
        fontSize: "12px",
        lineHeight: "14px",
        color: COLORS.LIGHT_GRAY,
      },

      tooltip: {
        maxWidth: "90%",
        marginBottom: 0,
        marginTop: "2px",
        fontSize: "14px",
        overflow: "hidden",
        lineHeight: "16px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: COLORS.LIGHT_GRAY,
        overflowWrap: "break-word",

        showAddress: {
          cursor: "pointer",
          color: COLORS.BLUE,
        },
      },
    },

    headingBox: {
      display: "flex",
      marginBottom: "10px",
      alignItems: "center",
      justifyContent: "space-between",

      desktopRating: {
        marginTop: "22px",
      },

      ratingComponent: {
        verticalAlign: "bottom",
      },

      withoutCategory: {
        height: "0 !important",
        margin: "0 !important",
        justifyContent: "flex-end",
      },

      [mainTheme.breakpoints.down("searchMobile")]: {
        display: "flex",
        marginBottom: "9px",
        justifyContent: "space-between",
      },

      rating: {
        margin: 0,
        top: "12px",
        right: "4.17%",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "21px",
        color: "grey.light",
        position: "absolute",
      },
    },
  },
};
