/* eslint-disable perfectionist/sort-objects */

import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  collapse: {
    paddingTop: "24px",
    textAlign: "center",
  },

  banners: {
    marginTop: "24px",

    [mainTheme.breakpoints.down("md")]: {
      marginTop: "12px",
    },
  },

  container: {
    padding: "0 16px 96px 16px",

    [mainTheme.breakpoints.down("md")]: {
      paddingBottom: "36px",
    },
  },

  heading: {
    paddingBottom: "32px",
  },

  title: {
    fontSize: 32,
    fontWeight: 500,
    marginBottom: "12px",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },

  subtitle: {
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "25px",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
};

export const bannerStyles = {
  priceWrapper: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "8px",
    alignItems: "center",
  },

  grid: {
    zIndex: 0,
    width: "100%",
    overflowY: "hidden",
    position: "relative",
  },

  creditBadge: {
    top: "16px",
    left: "16px",
    padding: "4px 6px",
    borderRadius: "4px",
    position: "absolute",
    color: "dominant.white",
    backgroundColor: "error.secondary",

    [mainTheme.breakpoints.down("md")]: {
      top: "10px",
      left: "10px",
      fontSize: "12px",
    },
  },

  bannersWrapper: {
    marginTop: "24px",

    [mainTheme.breakpoints.down("md")]: {
      marginTop: "12px",
    },
  },

  creditText: {
    textShadow: "none",
    padding: "2px 4px",
    borderRadius: "4px",
    color: "content.primary",
    backgroundColor: "accent.yellow.loan",
  },

  listItem: {
    cursor: "pointer",
    transition: "0.2s",
    overflow: "hidden",
    borderRadius: "8px",
    position: "relative",

    content: {
      padding: "12px 0 12px 0",
    },

    "&:hover img": {
      transform: "scale(1.09)",
    },

    "&:after": {
      inset: 0,
      content: "''",
      position: "absolute",
      background:
        "linear-gradient(12.45deg, rgba(4, 22, 44, 0.7) 4.68%, rgba(4, 22, 44, 0) 40.26%)",
    },
  },

  badge: {
    zIndex: 1,
    gap: "4px",
    left: "16px",
    bottom: "20px",
    display: "block",
    position: "absolute",
    color: "dominant.white",
    textShadow: "2px 2px 4px rgba(22, 22, 22, 0.4)",

    [mainTheme.breakpoints.down("md")]: {
      left: "10px",
      bottom: "10px",
    },
  },

  countryName: {
    fontWeight: 400,
    fontSize: "26px",

    [mainTheme.breakpoints.down("lg")]: {
      fontSize: "18px",
      display: "block",
    },

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "14px",
      display: "block",
    },
  },

  price: {
    fontWeight: 500,
    fontSize: "32px",

    [mainTheme.breakpoints.down("lg")]: {
      fontSize: "24px",
      display: "block",
    },

    [mainTheme.breakpoints.down("md")]: {
      left: "10px",
      bottom: "10px",
      fontSize: "18px",
      display: "block",
    },
  },

  labels: {
    display: "flex",
    paddingTop: "4px",
  },

  date: {
    fontSize: "18px",

    [mainTheme.breakpoints.down("lg")]: {
      fontSize: "16px",
      display: "block",
    },

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "14px",
      display: "block",
    },
  },
};
