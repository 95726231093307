export const styles = {
  tooltipText: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: "12px",
  },

  tooltipTitle: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "16px",
  },
};
